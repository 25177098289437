import { PropsWithChildren } from 'react'
import clsx from 'clsx'

import { ButtonIcon } from 'components/buttons'
import Spinner from 'components/Spinner'
import Title from 'components/Title'
import { IconClose } from 'icons'

import ModalDialog from '../Modal'

import { IInformationModalProps } from './types'

const InformationModal = ({
  isOpen,
  onClose,
  children,
  title,
  subtitle,
  titleVariant,
  className,
  isLoading
}: PropsWithChildren<IInformationModalProps>) => {
  return (
    <ModalDialog isOpen={isOpen} onClose={onClose}>
      <div
        className={clsx(
          'relative flex max-w-lg flex-col items-center gap-y-2 p-10',
          className
        )}
      >
        <div className="absolute top-5 right-5 ">
          <ButtonIcon rounded icon={IconClose} onClick={onClose}></ButtonIcon>
        </div>
        {title && (
          <Title variant={titleVariant} className="text-center">
            {title}
          </Title>
        )}

        {subtitle && <div>{subtitle}</div>}
        {children && <div className="text-center">{children}</div>}
        <Spinner
          className="pointer-events-none absolute top-3 left-3"
          isVisible={isLoading}
          size={42}
        />
      </div>
    </ModalDialog>
  )
}

export default InformationModal
